export const environment = {
  production: true,
  //portalUrl: 'http://localhost:4200/',
  portalUrl: 'https://flinkit-admin-v3.azurewebsites.net/',
  //baseUrl: 'https://flinkit-admin-staging.azurewebsites.net/api/',
  //baseUrl: 'https://flinkit-friends-server.azurewebsites.net/api/',
  baseUrl: 'https://flinkit-admin-v3.azurewebsites.net/api/',
  //baseUrl: 'http://localhost:3333/api/',
  //baseUrl: 'https://flinkit-admin-new.azurewebsites.net/api/',
  stripe: "pk_live_51I5e0dCZuGDlDgOcWG8UJEHtHQPAVtxp5MOpqCM1iIGRFOTQwNPmdw55PfGZD71f5bAZR95xq0w6zu9tTTAwBqp400mVHym879"
  //stripe: "pk_test_51I5e0dCZuGDlDgOcTP7POrk7eG627L5DsEAD9yheyE1lIzxh2Y6zNvT8A68BNfIzdVDFtAgNYrGlLh125iLREIi100pv8RkVjv"
};
