<div id="museumBg" >
  <div class="d-flex justify-content-center text-center pt-5 w-100">
    <img [src]="partner && partner.customLogo ? partner.customLogo : './assets/images/museums/logo_b.png'"
    width="120" alt="">

  </div>
  <div class="museum-leaderboard p-3">
    <div class="leaderboard">
      <div class="row mt-3">
        <div class="col-12 text-center">
          <h4 class="fi-text-black"><span i18n>Congratulations!</span>😊</h4>
        </div>
      </div>
    <div class="row mt-3">
        <div class="col-12">
          <div class="fi-segmented-control gray">
            <button class="fi-text-bold" [class]="pageConfig.activeTab === 'finishedScore' ? 'active': ''" (click)="setTab('finishedScore')" i18n>By points</button>
            <button class="fi-text-bold" [class]="pageConfig.activeTab === 'created' ? 'active': ''" (click)="setTab('created')" i18n>Latest</button>
          </div> 
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div class="fi-result-row p-3 shadow d-flex align-items-center mb-2" *ngFor="let player of hofs; let i = index;">
            <div class="mr-3">
              <div class="fi-map-marker-enemy bg-2">
                <img [src]="emojiService.getEmojiByIndex(player.players.length ? player.players[0].avatarIndex : 0).icon" width="30" alt="">
              </div>
            </div>
            <div class="text-left">
              <h5 class="mb-0">{{i+1}}. {{player.players.length ? player.players[0].name : ''}}</h5> {{player.finishedScore}} <span i18n>points</span>
            </div>
          </div>
        </div>
      </div>
      <button class="flinkint-btn primary btn-block mt-2 mb-3" [style.background-color]="partner ? partner.color : null" [style.border-color]="partner ? partner.color : null"    (click)="refresh()">
        {{locales.refresh}}
      </button>
      <div class="row text-center mt-3" *ngIf="partner && partner.isMuseum && partner.isDigitalMuseum">
        <div class="col-12 text-center fi-text-bold">
          <img [src]="(partner && partner.customLogo) ? partner.customLogo : './assets/images/pbe.png'"  width="90"alt="">
        </div>
      </div>
     
      <div class="row text-center mt-3" *ngIf="partner && partner.isMuseum && !partner.isDigitalMuseum ">
        <div class="col-12 text-center fi-text-bold">
          <img src="./assets/images/pbe.png"  width="90"alt="">
        </div>
      </div>

    <!--   <div class="row mt-3">
        <div class="col-12 text-center">
          <img class="mt-3" src="../../assets/images/pbe.png" width="80" alt="">
        </div>
      </div> -->
    </div>
   
  </div>
</div> 
